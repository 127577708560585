import type { MetaTag } from "~/types/layout";

export const setHead = (value: Array<MetaTag>) => {
  const meta = value.map((val) => {
    return { name: val.property, content: val.content };
  });

  useHead({
    meta,
  });
};
